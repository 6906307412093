@function color_tint($color, $percent)  { @return mix(white, $color, $percent * 1%); }
@function color_shade($color, $percent) { @return mix(black, $color, $percent * 1%); }
@function color_shift($color, $deltaHue, $desat) { @return adjust_hue(desaturate($color, $desat), $deltaHue)}
@function color($color, $deltaHue, $desat, $darken) { @return adjust_hue(desaturate(darken($color, $darken), $desat), $deltaHue)}

$color_NECITBlue: #80E1F6;
$color_NECITBlueComplement: #f68680;  // hue+180
$color_NECITBlueContrast: desaturate(darken($color_NECITBlue,60), 25); // 56,14 //#0c4350;
$color_NECITBluePale: #edfcff;
$color_NECITBlueLight: #b0edfb;
$color_NECITBlueMedium: #58d1ed;

$colour_NECITBlues: (
    color_NECITBlue: #80E1F6,
    color_NECITBlueComplement: #f68680,  // hue+180
    color_NECITBlueContrast: desaturate(darken($color_NECITBlue,60), 25), // 56,14 //#0c4350;
    color_NECITBluePale: #edfcff,
    color_NECITBlueLight: #b0edfb,
    color_NECITBlueMedium: #58d2ed,
    color_NECITTurquoise: #30d5c799,
    colour_Black: black,
    colour_NotAuthorised: #ffc7ce,
    colour_SemiAuthorised: #ffdac7,
    colour_FullyAuthorised: #c7ffdf,
);

:export {
  @each $key, $value in $colour_NECITBlues {
    #{unquote($key)}: $value;
  }
}
$color_NECITBody: desaturate(lighten($color_NECITBlue,14),65); //e1eaec

$color_NECITGrey: desaturate($color_NECITBlue, 70); //b0c2c6

$color_NECITGrey_VVDark: color_shade($color_NECITGrey, 90);
$color_NECITGrey_VDark: color_shade($color_NECITGrey, 80);
$color_NECITGrey_Dark: color_shade($color_NECITGrey, 50);
$color_NECITGrey_Mid: $color_NECITGrey;
$color_NECITGrey_Light: color_tint($color_NECITGrey, 40);
$color_NECITGrey_VLight: color_tint($color_NECITGrey, 75);
$color_NECITGrey_VVLight: color_tint($color_NECITGrey, 90);
$color_NECITGrey_VVVLight: color_tint($color_NECITGrey, 99);

$color_NECITDanger: color_shift($color_NECITBlue, +160, 0);
$color_NECITDangerText: color($color_NECITBlue, +160, 30, 15);
$color_NECITDangerSubheading: color_shift($color_NECITDanger, 0, 40);
$color_NECITWarn: color_shift($color_NECITBlue, -160, 0);
$color_NECITWarnText: color($color_NECITBlue, -160, 30, 15);

$color_NECITOpen: color_shift($color_NECITBlue, 0, 40); //#9BCFDB
$color_NECITOpenText: color($color_NECITBlue, 0, 30, 15); //#58bcd1
$color_NECITOpenContrast: color_shade($color_NECITOpen, 80);
$color_NECITOpenHighlight: color_shift($color_NECITBlue, 0, 0);

$color_NECITSuccess: color_shift($color_NECITBlue, -40, 40);
$color_NECITSuccessText: color($color_NECITBlue, -40, 30, 15);
$color_NECITSuccessContrast: color_shade($color_NECITSuccess, 80);
$color_NECITSuccessHighlight: color_shift($color_NECITBlue, -40, 0);

$color_NECITSecondary: $color_NECITGrey;
$color_NECITSecondaryText: color($color_NECITGrey, 0, 0, 15);
$color_NECITSecondaryContrast: color_shade($color_NECITSecondary, 80);
$color_NECITSecondaryHighlight: color_tint($color_NECITSecondary, 30);

$color_NECITCreate: color_shift($color_NECITBlue, +40, 40);
$color_NECITCreateText: color($color_NECITBlue, +40, 40, 10);
$color_NECITCreateContrast: color_shade($color_NECITCreate, 80);
$color_NECITCreateHighlight: color_shift($color_NECITBlue, +40, 0);

$color_NECITDelete: color_shift($color_NECITBlue, -160, 40);
$color_NECITDeleteText: color($color_NECITBlue, -160, 30, 15);
$color_NECITDeleteContrast: color_shade($color_NECITDelete, 80);
$color_NECITDeleteHighlight: color_shift($color_NECITBlue, -160, 0);

$color_Header: $color_NECITBlueContrast;

$color_SidebarBackground: $color_NECITGrey_VVDark;
$color_SidebarForeground: $color_NECITGrey_Mid;
$color_SidebarActive: $color_NECITBlue;
$color_SidebarActiveContrast: $color_NECITBlueContrast;
$color_SidebarHover: $color_NECITGrey_Dark;
$color_SidebarHoverContrast: $color_SidebarForeground;

$color_SidebarSubmenuBackground: $color_NECITGrey_VDark;
$color_SidebarSubmenuForeground: $color_SidebarForeground;
$color_SidebarSubmenuActive: $color_NECITBlue;
$color_SidebarSubmenuActiveContrast: $color_NECITBlueContrast;
$color_SidebarSubmenuHover: $color_SidebarSubmenuBackground;
$color_SidebarSubmenuHoverContrast: $color_NECITBluePale;
$color_SidebarSubmenuLine: $color_NECITBlueContrast;

// button colors until restyle is applied
$color_compat_cyan: #61a9ab;
$color_compat_cyan_hover: #4d8889;
$color_compat_purple: #777bb2;
$color_compat_purple_hover: #5a5e9d;
$color_compat_blue: #5f8cdc;
$color_compat_blue_hover: #4b70b0;