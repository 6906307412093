@import './colors.scss';

/* ********************************************************************************************* *\
 * Override standard antd styles
\* ********************************************************************************************* */

.ant-dropdown-menu {
    max-height: 500px;
    overflow: auto;
}

.ant-layout-header {
    height: 63px;
    padding: 0 50px;
    line-height: normal;
}

.ant-layout {
    box-sizing: border-box;
    font-size: 12px;
    font-family: inherit;
}

.ant-table-body {
    //horizontal scrollbar
    overflow-x: auto;
}

.ant-table-tbody>tr>td {
    padding: 5px 8px;
}
.ant-table-tbody>tr>td.ant-table-row-cell-break-word {
    word-break: normal;
}

.ant-table-thead>tr>th {
    padding: 12px 8px;
}
.ant-table-thead>tr>th.ant-table-row-cell-break-word {
    word-break: normal;
}

.ant-table-wrapper.clickable-row tbody tr {
    cursor: pointer;
}

.ant-table .ant-table-row.clickable-row {
    cursor: pointer;
}

// Fix antd 'search' inputs
.ant-input-search > input[type=text].ant-input {
    border-style: none!important;
    height: 22px;
}
.ant-input-search.ant-input-affix-wrapper {
    border-radius: 4px;
}
.ant-input-search-icon::before {
    border-left-style: none!important;
}
// end fix

.ant-picker-calendar.work-date-calendar {
    
    .ant-picker-panel .ant-picker-calendar-date {
        transition: none!important; // removes transient background colours
    }

    td.ant-picker-cell {
        
        cursor: default;

        .work-date.ant-picker-cell-inner {
            background-color: #fff; // cell around the actual date value has white background
        }

        .work-date .ant-picker-calendar-date-value {
            color:  $color_NECITOpenContrast;   // work dates have blue background
            background-color: $color_NECITOpen;
        }  
        
        .ant-picker-cell-inner .ant-picker-calendar-date-value {
            border: 1px solid #fff; // placeholder so stop cells resizing when hover border is added
            border-radius: 3px;
        }

        &:hover .ant-picker-cell-inner {
            background-color: #fff!important;
        }

        &:hover .ant-picker-cell-inner.work-date .ant-picker-calendar-date-value {
            background-color: $color_NECITOpen; // work dates keep blue background when hover
        }

        &:hover .ant-picker-cell-inner:not(.work-date) .ant-picker-calendar-date-value {
            background-color: #fff; // keep background white, unless it is a work date;
        }

        &.ant-picker-cell-selected .ant-picker-cell-inner {
            color: rgba(0,0,0, 0.65); // stop text going white now we have changed the background
        }

        &.ant-picker-cell-selected .ant-picker-cell-inner:not(.work-date) {
            background-color: #fff; // remove background from selected cell, unless it is a work date;
        }

        &.ant-picker-cell-today .ant-picker-cell-inner:not(.work-date) {
            background-color: #fff; // remove border from 'today';
        }

        &.ant-picker-cell-today .ant-picker-cell-inner::before {
            border-style: none; // remove border from 'today';
        }
    }

    .ant-picker-month-panel {

        td.ant-picker-cell .ant-picker-calendar-date-value {
            cursor: pointer;
        }

        .ant-picker-cell-selected .ant-picker-calendar-date-value {
            border: 1px solid $color_NECITGrey_Mid!important;
        }
    }
}

.ant-picker-calendar.work-date-calendar.work-date-calendar-editable {

    td.ant-picker-cell {

        cursor: pointer;

    /*    .work-date {
            color:  $color_NECITOpenContrast;
            background-color: $color_NECITOpen;
        }*/

        &:hover .ant-picker-cell-inner .ant-picker-calendar-date-value {
            border: 1px solid $color_NECITGrey_Mid;
        }

        &:hover .ant-picker-cell-inner.work-date .ant-picker-calendar-date-value {
            background-color: $color_NECITOpenHighlight;
        }
    }
}

.ant-radio-group.vertical-radio {
    .ant-radio-wrapper {
        display:block;
    }
}

.ant-checkbox-group.vertical-checkbox {
    .ant-checkbox-wrapper {
        display:block;
    }
}

.ant-badge.lowkey > .ant-badge-count {
    background-color:#fff;
    color: $color_NECITGrey_Mid;
    box-shadow:0 0 0 1px $color_NECITGrey_Mid inset;
}
