@import './styles.scss';
@import './antd-overrides.scss';
@import 'react-redux-toastr/src/styles/index';
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
/* EVERYTHING above here is original */
@import './colors.scss';

body {
  margin: 0;
  padding: 0;
  background-color: $color_NECITBody!important;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.system-notice-page {
  padding: 50px;
}
.system-notice-page h1 { color: $color_NECITDanger; }
.system-notice-page h2 { color: $color_NECITDangerSubheading; }
.system-notice-page p { font-size: 20px; margin-bottom: 0.5em; }

.app {
  text-align: center;
  font-family: 'Roboto', sans-serif!important;
  margin: 0;
  padding: 0;
}

.fix-to-bottom{
  position: fixed;
  bottom: 0;
  text-align: center;
}

.to-the-left{
  text-align: left !important;
}

.content > h1, h2, h3, h4, h5, h6 {
  color: $color_Header;
}

.height-35 { height:35px!important; }
.height-70 { height:70px!important; }
.height-half { height:50%!important; }
.width-35 { width:35px!important; }
.width-70 { width:70px!important; }
.width-half { width:50%!important; }
.m-t-11 { margin-top:11px!important; }
.m-r-11 { margin-right:11px!important; }
.m-b-11 { margin-bottom:11px!important; }
.m-l-11 { margin-left:11px!important; }
.m-11 { margin:11px!important; }
.p-t-11 { padding-top:11px!important; }
.p-r-11 { padding-right:11px!important; }
.p-b-11 { padding-bottom:11px!important; }
.p-l-11 { padding-left:11px!important; }
.p-11 { padding:11px!important; }

.clickable { cursor: pointer; }
.clickable-hover:hover { color: $color_NECITBlueMedium; }

.form-horizontal .control-label {
  padding-top: 0;
}

.panel-primary > .panel-heading {
  background: black;
}

.text-disabled {
  color: $color_NECITGrey_Mid!important;
  cursor: default;
}

.CentreText {
    text-align: center;
}
.text-open, .text-blue { color: $color_NECITOpenText!important; }
.text-create, .text-purple { color: $color_NECITCreateText!important; }
.text-success, .text-green { color: $color_NECITSuccessText!important; }
.text-delete, .text-orange { color: $color_NECITDeleteText!important; }
.text-danger, .text-red { color: $color_NECITDangerText!important; }
.text-lowkey, .text-grey { color: $color_NECITGrey_Mid!important; }

.color-open, .color-blue { color: $color_NECITOpenHighlight!important; }
.color-create, .color-purple { color: $color_NECITCreateHighlight!important; }
.color-success, .color-green { color: $color_NECITSuccessHighlight!important; }
.color-delete, .color-orange { color: $color_NECITDeleteHighlight!important; }
.color-danger, .color-red { color: $color_NECITDanger!important; }
.color-lowkey, .color-grey { color: $color_NECITGrey_Light!important; }

.border-blue { border: solid 1px $color_NECITOpenHighlight!important; }
.border-purple { border: solid 1px $color_NECITCreateHighlight!important; }
.border-green { border: solid 1px $color_NECITSuccessHighlight!important; }
.border-orange { border: solid 1px $color_NECITDeleteHighlight!important; }
.border-red { border: solid 1px $color_NECITDanger!important; }
.border-grey { border: solid 1px $color_NECITGrey_Dark!important; }
.border-lowkey { border: solid 1px #d9d9d9; } // matches antd input fields

.background-open, .background-blue { background-color: $color_NECITOpenText!important; }
.background-create, .background-purple { background-color: $color_NECITCreateText!important; }
.background-success, .background-green { background-color: $color_NECITSuccessText!important; }
.background-delete, .background-orange { background-color: $color_NECITDeleteText!important; }
.background-danger, .background-red { background-color: $color_NECITDangerText!important; }
.background-lowkey, .background-grey { background-color: $color_NECITGrey_Mid!important; }
.background-body { background-color: $color_NECITBody!important; }

.sigCanvas{
  border: 2px solid black;
}

.btn-open, .btn-create {
  color: #fff;
  background-color: $color_compat_blue;
  border-style: none;
  
  &:hover {
    color: #fff;
    background-color: $color_compat_blue_hover;
  }

  &:focus { color: #fff; }
}

.btn-save, .btn-edit {
  color: #fff;
  background-color: $color_compat_cyan;
  border-style: none;
  
  &:hover {
    color: #fff;
    background-color: $color_compat_cyan_hover;
  }

  &:focus { color: #fff; }
}

.btn-cancel, .btn-delete {
  color: #fff;
  background-color: $color_compat_purple;
  border-style: none;
  
  &:hover {
    color: #fff;
    background-color: $color_compat_purple_hover;
  }

  &:focus { color: #fff; }
}

.btn-refresh {
  color: $color_NECITGrey_Mid;
  background-color: #fff;
  border-style: none;
  
  &:hover {
    color: #fff;
    background-color: $color_NECITGrey_Mid;
  }

  &:focus { color: $color_NECITGrey_Mid; }
}

/*  
/-----------------------------------
| Ready For Overall restyle --->
\-----------------------------------

.btn-open {
  color: $color_NECITOpenContrast;
  background-color: $color_NECITOpen;
  border: 1px solid $color_NECITOpenContrast;
  border-radius: 4px;

  &:hover {
    background-color: $color_NECITOpenHighlight;
  }

  &:focus { color: $color_NECITOpenContrast; }
}

.btn-save {
  color: $color_NECITSuccessContrast;
  background-color: $color_NECITSuccess;
  border: 1px solid $color_NECITSuccessContrast;
  border-radius: 4px;

  &:hover {
    background-color: $color_NECITSuccessHighlight;
  }

  &:focus { color: $color_NECITSuccessContrast; }
}

.btn-create {
  color: $color_NECITCreateContrast;
  background-color: $color_NECITCreate;
  border: 1px solid $color_NECITCreateContrast;
  border-radius: 4px;

  margin: 0px 10px;

  &:hover {
    background-color: $color_NECITCreateHighlight;
  }

  &:focus { color: $color_NECITCreateContrast; }
}

.btn-delete {
  color: $color_NECITDeleteContrast;
  background-color: $color_NECITDelete;
  border: 1px solid $color_NECITDeleteContrast;
  border-radius: 4px;

  margin: 0px 10px;

  &:hover {
    background-color: $color_NECITDeleteHighlight;
  }

  &:focus { color: $color_NECITDeleteContrast; }
}

.btn-cancel {
  color: $color_NECITSecondary;
  background-color: transparent;
  border: 1px solid $color_NECITSecondaryHighlight;
  border-radius: 4px;

  margin: 0px 10px;

  &:hover {
    color: $color_NECITSecondaryContrast;
    background-color: $color_NECITSecondaryHighlight;
    border-color: $color_NECITSecondaryContrast;
  }

  &:focus { color: $color_NECITSecondary; }
}
*/

/*=================== EVERYTHING below here is original ===================*/
// Custom Style of Theme

.app {

  .d-flex {
    display: flex;
      &__column {
        flex-direction: column;
      }

      &__row {
		  flex-direction: row;
	  }
  }

  .container-loader {
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.7);
    .loader {
      border: 3px solid #f3f3f3;
      border-radius: 50%;
      border-top: 2px solid blue;
      border-bottom: 2px solid blue;
      width: 150px;
      height: 150px;
      animation: spin 2s linear infinite;
    }

    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

}

.login.login-v2 {
  background: rgba(0, 0, 0, 0.4);
  color: #ccc;
  width: 450px;
  position: absolute;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.resendEmail {
  &.modal {
    &.fade {
      opacity: 1 !important;
    }
  }
}

// .ant-table {
//   display: block;

//   @media screen and (max-width: 600px) {
//     &-thead {
//       display: none;
//     }

//     &-thead>tr,
//     &-tbody>tr {

//       th,
//       td {
//         &:first-of-type {
//           padding-top: 1rem;
//         }

//         &:last-of-type {
//           padding-bottom: 1rem;
//         }
//       }

//       >th,
//       >td {
//         display: block;
//         width: auto !important;
//         border: none;
//         padding: 0 1rem;
//         font-size: 1.1rem;

//         &:last-child {
//           border-bottom: 1px solid #eee;
//         }
//       }
//     }
//   }
// }

@media screen and (max-width: 992px) {
  .app {
    .d-flex {
      display: flex;
        &__row {
          flex-direction: column;
        }
      }
    }
}

@media screen and (max-width: 770px) {
  .login.login-v2 {
    width: 95%;
    margin: 0 auto;
    left: 0;
    top: 15%;
  }

  
}

// End Custom Style of Theme
